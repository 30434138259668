import { h, VNode } from "preact";
import { useEffect, useState } from "preact/hooks";
import s from "./calculator.module.css";
import { CalculatorTabs, TabToggle } from "../tabToggle";
import { RateCalculatorTab } from "../tabs/rateCalculatorTab";
import { CompareRatesTab } from "../tabs/compareRatesTab";
import { fetchRates } from "../../api/fetchRates";
import { getCurrencyFromUrl, getDefaultFromCurrency } from "./utils";
import {
  countryCodeToCurrencyLongName,
  currenciesConfig,
} from "../../i18n/currenciesConfig";

interface CalculatorProps {
  fetchRatesApi: string;
  downloadNalaLink: string;
  faqLink: string;
  defaultToCurrencyCode: string;
  theme: string;
  messages?: Message[];
}

export default function Calculator(props: CalculatorProps): VNode {
  const [currentTab, setCurrentTab] = useState<CalculatorTabs>(
    CalculatorTabs.RATE_CALCULATOR
  );
  const [rates, setRates] = useState<CalculatorRate[]>([]);
  const [fromCurrencyCode, setFromCurrencyCode] = useState<string | null>(null);
  const [toCurrencyCode, setToCurrencyCode] = useState<string | null>(null);
  const [isFetchingRates, setIsFetchingRates] = useState(true);

  const [selectedFromCurrency, setSelectedFromCurrency] =
    useState<CalculatorCurrency | null>(null);
  const [selectedToCurrency, setSelectedToCurrency] =
    useState<CalculatorCurrency | null>(null);

  useEffect(() => {
    const currentUrl = window.location.href;
    const currencyCode = getCurrencyFromUrl(
      currentUrl,
      props.defaultToCurrencyCode
    );
    setToCurrencyCode(currencyCode);

    const defaultFromCurrency = getDefaultFromCurrency();
    setFromCurrencyCode(defaultFromCurrency);
  }, [props.defaultToCurrencyCode]);

  useEffect(() => {
    if (fromCurrencyCode && !selectedFromCurrency) {
      const fromCurrency = {
        currencyCode: fromCurrencyCode,
        currencyName: countryCodeToCurrencyLongName(
          currenciesConfig[fromCurrencyCode].countryCodes[0]
        ),
        countryCode: currenciesConfig[fromCurrencyCode].countryCodes[0],
      };
      setSelectedFromCurrency(fromCurrency);
    }

    if (toCurrencyCode && !selectedToCurrency) {
      const toCurrency = {
        currencyCode: toCurrencyCode,
        currencyName: countryCodeToCurrencyLongName(
          currenciesConfig[toCurrencyCode].countryCodes[0]
        ),
        countryCode: currenciesConfig[toCurrencyCode].countryCodes[0],
      };
      setSelectedToCurrency(toCurrency);
    }
  }, [
    fromCurrencyCode,
    toCurrencyCode,
    selectedFromCurrency,
    selectedToCurrency,
  ]);

  useEffect(() => {
    if (fromCurrencyCode) {
      const fetchRatesData = async () => {
        setIsFetchingRates(true);
        try {
          const fetchedRates = await fetchRates(props.fetchRatesApi);
          setRates(fetchedRates);
        } finally {
          setIsFetchingRates(false);
        }
      };

      fetchRatesData();
    }
  }, [props.fetchRatesApi, fromCurrencyCode, toCurrencyCode]);

  const handleTabChange = (tab: CalculatorTabs) => {
    setCurrentTab(tab);
  };

  const handleFromCurrencyChange = (currency: CalculatorCurrency) => {
    setSelectedFromCurrency(currency);
  };

  const handleToCurrencyChange = (currency: CalculatorCurrency) => {
    setSelectedToCurrency(currency);
  };

  return (
    <div className="nala-calculator" data-theme={props.theme}>
      <div
        className={`${s.container} ${
          currentTab === CalculatorTabs.RATE_CALCULATOR
            ? s.rateCalculator
            : s.compareRates
        }`}
      >
        <TabToggle onTabChange={handleTabChange} />

        {currentTab === CalculatorTabs.RATE_CALCULATOR ? (
          <RateCalculatorTab
            downloadNalaLink={props.downloadNalaLink}
            faqLink={props.faqLink}
            fromCurrency={selectedFromCurrency}
            toCurrency={selectedToCurrency}
            onFromCurrencyChange={handleFromCurrencyChange}
            onToCurrencyChange={handleToCurrencyChange}
            rates={rates}
            isFetchingRates={isFetchingRates}
            messages={props.messages}
          />
        ) : (
          <CompareRatesTab
            rates={rates}
            downloadNalaLink={props.downloadNalaLink}
            fromCurrency={selectedFromCurrency}
            toCurrency={selectedToCurrency}
            onFromCurrencyChange={handleFromCurrencyChange}
            onToCurrencyChange={handleToCurrencyChange}
            messages={props.messages}
          />
        )}
      </div>
    </div>
  );
}
