import { h, VNode } from "preact";
import s from "./dynamicMessage.module.css";

interface DynamicMessageProps {
  messageData: Message | null;
}

export const DynamicMessage = ({
  messageData,
}: DynamicMessageProps): VNode | null => {
  if (!messageData) return null;

  return (
    <div className={s.container}>
      <p className={s.text}>{messageData.text}</p>
      {messageData.link && (
        <a
          href={messageData.link.url}
          className={s.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {messageData.link.text}
        </a>
      )}
    </div>
  );
};
