import { currenciesConfig } from "../i18n/currenciesConfig";
import fx from "./fx";

export const calculate = (
  state: CalculatorState,
  direction: "to" | "from" = "to"
): CalculatorState => {
  const { fromCurrency, toCurrency, fromAmount, toAmount, rates } = state;

  if (!fromCurrency || !toCurrency || !rates) {
    return state; // Return the current state if essential data is missing
  }

  const rate = rates.find(
    (rate) =>
      rate.sourceCurrency.countryCode === fromCurrency.countryCode &&
      rate.destinationCurrency.countryCode === toCurrency.countryCode
  );

  if (!rate) {
    return state; // Return the current state if no matching rate is found
  }

  if (direction === "to") {
    return {
      ...state,
      toAmount: fx.convertToCurrency({
        fromAmount: Number(fromAmount),
        exchangeRate: rate.rate,
        toCurrency: currenciesConfig[toCurrency.currencyCode],
      }),
    };
  }
  return {
    ...state,
    fromAmount: fx.convertFromCurrency({
      fromAmount: Number(toAmount),
      exchangeRate: rate.rate,
      toCurrency: currenciesConfig[fromCurrency.currencyCode],
    }),
  };
};

export const reducer = (
  state: CalculatorState,
  action: Action
): CalculatorState => {
  switch (action.type) {
    case "fromCurrencyChange":
      return calculate({
        ...state,
        fromCurrency: action.payload,
        fromAmount: "1000", // Reset to a default amount when currency changes
      });
    case "toCurrencyChange":
      return calculate({
        ...state,
        toCurrency: action.payload,
      });
    case "fromAmountChange":
      return calculate({
        ...state,
        fromAmount: action.payload,
      });
    case "toAmountChange":
      return calculate(
        {
          ...state,
          toAmount: action.payload,
        },
        "from"
      );
    case "addPairs":
      return calculate({
        ...state,
        rates: action.payload,
      });
    default:
      return state;
  }
};
